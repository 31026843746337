<template>
    <el-card>
        <el-form ref="form" :rules="rules" :model="form" label-width="90px">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="岗位:" prop="informationType">
                        <el-select class="select" v-model="form.informationType" placeholder="请选择资讯类型">
                            <el-option v-for="(item, index) in infomationList" :key="index" :label="item.dictionaryName"
                                :value="item.dictionaryCode"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="姓名:" prop="informationType">
                        <el-select class="select" v-model="form.informationType" placeholder="请选择资讯类型">
                            <el-option v-for="(item, index) in infomationList" :key="index" :label="item.dictionaryName"
                                :value="item.dictionaryCode"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="简介" prop="content">
                <fuwenben ref="editor" v-model="form.content" @onClick="onClick">
                </fuwenben>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="上传照片">
                        <el-upload :class="imageUrl1 ? '' : 'avatar-uploader'" :action="action" :show-file-list="false"
                            :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess1">
                            <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="footer">
            <el-button @click="$emit('close')">取 消</el-button>
            <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
        </div>
    </el-card>
</template>
  
<script>
import { postRequestAnthor } from '@/api'
import E from 'wangeditor'
import { formatTime } from '@/utils/utils'
import { imgaction } from 'src/utils/cities'
import fuwenben from '@/views/infomationManagement/components/fuwenben'
export default {
    data() {
        return {
            action: imgaction,
            form: {
                informationTitle: '',
                informationType: '',
                beginDate: '',
                endDate: '',
                content: '',
                updateBy: '1',
                createBy: '2',
            },
            imageUrl1:'',
            infomationList:[],
            rules: {
                informationTitle: [
                    { required: true, message: '请输入资讯标题信息', trigger: 'blur' }
                ],
                informationType: [
                    { required: true, message: '请选择资讯类型', trigger: 'change' }
                ],
            },
          
        }
    },
    components: { fuwenben },
    mounted() {
      
    },
    methods: {
        handleAvatarSuccess1(res, file) {
            console.log(res, 'res', file, 'file')
            if (res.code == 200) {
                this.$message({
                    message: '图片上传成功',
                    type: 'success'
                })
                this.form.coverTypeUrl = res.data
                this.imageUrl1 = URL.createObjectURL(file.raw);
            }
        },
        onClick(e, editor) {
            console.log('Element clicked')
            console.log(e)
            console.log(editor)
        },

        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传封面图片大小不能超过 2MB!');
            }
            return isLt2M;
        },

     
        sureCommit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    if (this.form.linkUrl || this.form.content) {
                        if (this.info != '') {
                            postRequestAnthor('/information/update', this.form).then(res => {
                                console.log(res)
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                })
                                this.$emit('closeAddinfo')
                            })
                        } else {
                            postRequestAnthor('/information/addsave', this.form).then(res => {
                                console.log(res, '提交结果')
                                this.$message({
                                    message: '新增成功',
                                    type: 'success'
                                })
                                this.$emit('closeAddinfo')
                            })
                        }
                    } else {
                        this.$message({
                            message: '请输入URL或者资讯文案',
                            type: 'warning'
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>
  
<style lang='scss' scoped>
.select {
    width: 100% !important;
}

#word {
    width: 100%;
    height: 200px;
}
.footer{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.ql-editor {
    height: 200px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #5479FF;
    width: 147px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 147px;
    height: 178px;
    display: block;
}

.avatar[data-v-6df196da] {
    width: 100%;
    height: 100%;
}

.el-range-editor {
    width: 100% !important;
}

.avatar-uploader {
    width: 147px;
    border: 1px dashed #5479FF;

}

.el-card {
    margin-top: -20px;
}

.dialog-footer {
    margin-top: -20px !important;
}
</style>